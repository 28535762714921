import React from "react"
import { API_URL, LOBBY_VERSION } from "gatsby-env-variables"

const defaultState = {
  playUrl: "",
  isLogin: "",
}
const PlayUrlContext = React.createContext(defaultState)

class PlayUrlProvider extends React.Component {
  state = {
    playUrl: "",
  }
  isMobile =
    /Android|iPod|iPad|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  isLogin = JSON.parse(localStorage.getItem('userData'))
  componentDidMount() {
    // fetch(`${API_URL}${LOBBY_VERSION}`, { method: "POST" })
    //   .then(response => response.json())
    //   .then(({ data: { desktopLogin, mobileLogin, desktop, mobile } }) => {
    //     this.setState({
    //       playUrl: this.isMobile && this.isLogin ? mobile :
    //       this.isMobile && !this.isLogin ? mobileLogin :
    //       !this.isMobile && this.isLogin ?  desktop :
    //       desktopLogin,
    //       guessUrl: this.isMobile ? mobile : desktop
    //     })
    //   })
  }
  render() {
    const { children } = this.props
    const { playUrl, guessUrl } = this.state
    return (
      <PlayUrlContext.Provider
        value={{
          playUrl,
          guessUrl
        }}
      >
        {children}
      </PlayUrlContext.Provider>
    )
  }
}
export default PlayUrlContext
export { PlayUrlProvider }
